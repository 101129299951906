.top-to-btm {
    position: relative;
    font-size: 50px;
}

.icon-position {
    position: fixed;
    bottom: 90px;
    right: 25px;
    z-index: 20;
}

.icon-style {
    background-color: #ff9933;
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
    z-index: 1000;
}

.icon-style:hover {
    animation: none;
    background: #fff;
    color: #ff9933;
    border: 2px solid #ff9933;
}

@keyframes movebtn {
    0% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(20px);
    }
    50% {
        transform: translateY(0px);
    }
    75% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}