.loading-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    overflow: hidden;
    top: 0;
    left: 0;
}

.loading-container.loaded {
    visibility: hidden;
    opacity: 0;
}

.loading-img {
    min-width: 250px;
    width: 20vh;
}

.loading-container.transparent-background {
    background-color: transparent;
}

.loading-container.white-background {
    background-color: white;
}

.loading-container.w100 {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}