html {
    box-sizing: border-box;
    height: 100vh;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 16px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin: 0;
    padding: 0;
}

body {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #4D1919;
    background-color: #FFFFFF;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    position: relative;
    height: 100vh;
}

#root {
    height: 100vh;
}

main {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 768px) {
    main {
        min-height: calc(100vh - 84px);
    }
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
}

p {
    margin-top: 0;
}

ul, ol, dl {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

a,
button,
input,
select {
    transition-property: transform, background, background-color, border-color, color;
    transition-duration: 0.3s;
}

textarea {
    resize: vertical;
}

a {
    color: #4D1919;
    text-decoration: none;
}

a:hover {
    color: #FF2A00;
    text-decoration: none;
}

a:focus {
    outline: none;
}

img {
    vertical-align: middle;
}

a,
area,
button,
[role=button],
input,
label,
select,
summary,
textarea {
    touch-action: manipulation;
}

[role=button] {
    cursor: pointer;
}

[hidden] {
    display: none !important;
}

.color-likes {
    color: #EE3743;
}

.color-accent {
    color: #3798EE;
}

.color-benefits {
    color: #0BD592;
}

.color-default {
    color: #4D1919;
}

.action {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 32px;
    line-height: 100%;
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;
    color: #4D1919;
}

.action--setting {
    display: flex;
    align-items: center;
    gap: 12px;
}

.action--setting .ico-setting {
    font-size: 24px;
}

.action--setting .ico-chevron-down {
    font-size: 10px;
}

.action:hover {
    cursor: pointer;
    text-decoration: none;
    opacity: 1;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-05 {
    margin-bottom: 0.5rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 1rem !important;
}

.pb-05 {
    padding-bottom: 0.5rem !important;
}

.link {
    color: #FF9933;
    cursor: pointer;
}

.link:hover {
    text-decoration: none;
}

.text-start {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.launch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;
}

.launch [class^=ico-], .launch [class*=" ico-"] {
    font-size: 48px;
}

.launch__status {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.75rem;
}

.launch__status [class^=ico-], .launch__status [class*=" ico-"] {
    font-size: 20px;
}

.banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sponsor {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.sponsor span {
    color: rgba(32, 88, 115, 0.4);
    font-size: 14px;
    line-height: 100%;
}

.message {
    padding: 24px;
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    gap: 1rem;
    max-width: 327px;
}

.slider {
    position: relative;
    z-index: 999;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    overflow: hidden;
}

.slider .play-prev,
.slider .play-next {
    width: 58px;
    height: 58px;
    border-radius: 58px;
    position: absolute;
    display: block;
    background-color: white;
    top: 50%;
    transform: translateY(-50%);
}

.slider .play-prev {
    right: -136px;
}

.slider .play-next {
    left: -136px;
}

.slider .prev-slide,
.slider .next-slide {
    position: relative;
    display: none;
}

@media screen and (min-width: 1060px) {
    .slider .prev-slide,
    .slider .next-slide {
        display: flex;
    }
}

.slider .prev-slide {
    transform: translateX(-50%);
}

.slider .prev-slide:hover {
    transform: translateX(-50%);
}

.slider .next-slide {
    transform: translateX(50%);
}

.slider .next-slide:hover {
    transform: translateX(50%);
}

@font-face {
    font-family: "icopeoplefirst";
    src: url("../fonts/icopeoplefirst.eot?rxlhgy");
    src: url("../fonts/icopeoplefirst.eot?rxlhgy#iefix") format("embedded-opentype"), url("../fonts/icopeoplefirst.ttf?rxlhgy") format("truetype"), url("../fonts/icopeoplefirst.woff?rxlhgy") format("woff"), url("../fonts/icopeoplefirst.svg?rxlhgy#icopeoplefirst") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^=ico-], [class*=" ico-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icopeoplefirst", Sans-serif !important;
    speak-as: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ico-upload-file:before {
    content: "\e92b";
}

.ico-check:before {
    content: "\e92a";
}

.ico-doc:before {
    content: "\e929";
}

.ico-chevron-doble-down:before {
    content: "\e928";
}

.ico-upload:before {
    content: "\e927";
}

.ico-pencil:before {
    content: "\e926";
}

.ico-commerce:before {
    content: "\e925";
}

.ico-heart-check:before {
    content: "\e920";
}

.ico-clock:before {
    content: "\e921";
}

.ico-money:before {
    content: "\e922";
}

.ico-calendar:before {
    content: "\e923";
}

.ico-filters:before {
    content: "\e924";
}

.ico-options:before {
    content: "\e91e";
}

.ico-back-circle:before {
    content: "\e91f";
}

.ico-plus:before {
    content: "\e91d";
}

.ico-upholder:before {
    content: "\e91a";
}

.ico-dislike:before {
    content: "\e91b";
}

.ico-like:before {
    content: "\e91c";
}

.ico-question-fill:before {
    content: "\e919";
}

.ico-heart:before {
    content: "\e918";
}

.ico-love:before {
    content: "\e917";
}

.ico-chevron-end:before {
    content: "\e915";
}

.ico-questions:before {
    content: "\e916";
}

.ico-info-circle:before {
    content: "\e903";
}

.ico-questions-circle:before {
    content: "\e911";
}

.ico-send:before {
    content: "\e912";
}

.ico-wsp:before {
    content: "\e913";
}

.ico-search:before {
    content: "\e914";
}

.ico-close-line:before {
    content: "\e910";
}

.ico-dance:before {
    content: "\e909";
}

.ico-sport:before {
    content: "\e90a";
}

.ico-taxi:before {
    content: "\e90b";
}

.ico-store:before {
    content: "\e90c";
}

.ico-medic:before {
    content: "\e90d";
}

.ico-dinnerware:before {
    content: "\e90e";
}

.ico-box:before {
    content: "\e90f";
}

.ico-star:before {
    content: "\e908";
}

.ico-eye:before {
    content: "\e907";
}

.ico-route:before {
    content: "\e900";
}

.ico-close:before {
    content: "\e906";
}

.ico-chevron-down:before {
    content: "\e901";
}

.ico-setting:before {
    content: "\e902";
}

.ico-notify:before {
    content: "\e904";
}

.ico-profile:before {
    content: "\e905";
}

.title {
    font-size: 1.2rem;
    line-height: 32px;
    font-weight: 700;
}

.subtitle, .profile__name {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}

.headline {
    font-size: 19px;
    line-height: 28px;
    font-weight: 500;
}

.subhead {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
}

.subhead--icon {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.lead, .input--search {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.lead--light {
    line-height: 20px;
    font-weight: 400;
}

.caption, .card__corporation {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.card-destacada .caption, .card-destacada .card__corporation {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
}

.card__info .caption {
    overflow: hidden;
    height: 40px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.caption a, .card__corporation a {
    text-decoration: underline;
}

.btn {
    border: none;
    background-color: transparent;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: white;
    padding-block: 12px;
    padding-inline: 60px;
    border-radius: 1.25rem;
    transition: background 0.3s ease;
    position: relative;
    z-index: 3;
}

.button-container {
    position: relative;
    border-radius: 1.25rem;
    background: #ff9933;
    background: linear-gradient(135deg, #ff9933 0%, #ff2a00 80%);
    z-index: 1;
    overflow: hidden;
}

.button-container::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 1s ease;
    background: #ff9933;
    opacity: 0;
}

.btn[disabled] {
    text-decoration: none;
    cursor: not-allowed;
    background: #ff9933;
}

.btn:hover {
    text-decoration: none;
    cursor: pointer;
}

.button-container:hover::after {
    opacity: 1;
}

.btn[disabled]:hover {
    text-decoration: none;
    cursor: not-allowed;
    background-color: #b2b2b2;
}

.btn--accent {
    background-color: #3798EE;
}

.btn--outline {
    background-color: transparent;
    color: rgba(32, 88, 115, 0.7);
    border: solid 1px rgba(32, 88, 115, 0.2);
}

.btn--outline:hover {
    border: solid 1px rgba(32, 88, 115, 0.2);
    background-color: transparent;
}

.btn--benefits {
    background-color: #0BD592;
}

.btn--likes {
    background-color: #EE3743;
}

.btn--light {
    background-color: white;
    color: #4D1919;
}

.btn--light:hover {
    background-color: white;
}

.btn--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding-inline: 1.5rem;
}

.btn--shadow {
    box-shadow: 0 0.25rem 0.625rem 0 rgba(32, 88, 115, 0.05), 0 0.25rem 0.625rem 0 rgba(32, 88, 115, 0.1);
}

.float {
    position: fixed;
    bottom: 24px;
    right: 24px;
    display: none;
    z-index: 999;
}

.float__button {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    background-color: #EE3743;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.05), 0 -4px 1px 0 rgba(255, 255, 255, 0.2), 0 -1px 0px 0 rgba(0, 0, 0, 0.3);
    padding: 0;
    line-height: 100%;
    border: none;
}

.float__button i {
    font-size: 22px;
}

.float__button > .ico-close-line {
    display: none;
}

.float__button.is-active > .ico-questions {
    display: none;
}

.float__button.is-active > .ico-close-line {
    display: block;
}

.float__button:hover {
    cursor: pointer;
}

.float__tooptip {
    position: absolute;
    top: -24px;
    transform: translateY(-100%);
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 250px;
    background-color: #EE3743;
    color: white;
    font-size: 14px;
    padding: 16px 20px;
    border-radius: 28px;
    display: none;
}

.float__options {
    position: absolute;
    top: -24px;
    transform: translateY(-100%);
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 310px;
    background-color: white;
    color: white;
    font-size: 14px;
    padding: 16px;
    border-radius: 8px;
    display: none;
}

.float__options.is-active {
    display: flex;
}

.float:hover > .float__tooptip {
    display: flex;
}

@media screen and (min-width: 1060px) {
    .float {
        display: flex;
        flex-direction: column;
    }
}

.dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.dots__item {
    background-color: #4D1919;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;
}

.dots__item.is-active {
    opacity: 1;
}

.input {
    width: 100%;
    border: none;
    font-size: 0.875rem;
    padding-block: 12px;
    padding-inline: 1.25rem;
    border-radius: 48px;
    min-height: 48px;
}

.input::placeholder {
    opacity: 0.3;
}

.input--shadow {
    box-shadow: 0 4px 10px 0 rgba(32, 88, 115, 0.05), 0 4px 10px 0 rgba(32, 88, 115, 0.1);
}

.input--search {
    background-color: #E6EDF2;
    text-align: center;
    color: #4D1919;
    font-family: "icopeoplefirst", "Poppins", sans-serif;
}

.input--search::placeholder {
    color: #ACC0CB;
}

.input:focus {
    outline: none;
}

.private {
    background-color: transparent;
    border: none;
    padding: 0;
    opacity: 0.4;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.private i {
    font-size: 16px;
}

.private.is-active {
    opacity: 1;
}

.private:hover {
    cursor: pointer;
    opacity: 1;
}

.textarea {
    width: 100%;
    background-color: white;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.05);
    padding: 1rem;
    border-radius: 20px;
    border: none;
    font-family: "Poppins", sans-serif;
    color: #4D1919;
    font-size: 1rem;
    line-height: 24px;
    resize: none;
}

.textarea::placeholder {
    color: #BDCDD4;
}

.files {
    position: relative;
}

.files input {
    position: absolute;
    visibility: hidden;
}

.files input:valid ~ label i.ico-upload-file {
    color: #007AE7;
}

.files input:valid ~ label i.ico-upload-file::before {
    font-family: "Poppins", sans-serif;
    content: "Cambiar";
}

.files label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.05);
    line-height: 100%;
    gap: 1rem;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form__field.is-password {
    position: relative;
}

.form__field.is-password .input {
    padding-right: 60px;
}

.form--search .form__field {
    position: relative;
}

.form--search .label {
    position: absolute;
    z-index: 1;
    font-weight: 600;
    left: 0;
    right: 0;
    margin-inline: auto;
    top: 50%;
    transform: translateY(-50%);
    gap: 8px;
    display: flex;
    align-items: center;
    opacity: 0.4;
}

.form--search .label i {
    font-size: 28px;
    color: #4D1919;
}

.form--search .input {
    position: relative;
    z-index: 2;
    background-color: transparent;
    min-width: 240px;
    padding-inline: 24px;
    font-size: 16px;
    font-weight: bold;
    color: #4D1919;
}

.form--search .input:focus {
    background-color: rgba(32, 88, 115, 0.05);
}

.form--search .input:focus + .label {
    display: none;
}

.form--search .input:valid {
    background-color: rgba(32, 88, 115, 0.05);
}

.form--search .input:valid + .label {
    display: none;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile__avatar {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.profile__avatar img {
    width: 100%;
    height: 100%;
}

.profile .lead, .profile .input--search {
    opacity: 0.7;
}

.profile__name {
    margin-bottom: 8px;
}

.profile--small .profile__avatar {
    width: 56px;
    height: 56px;
    border-radius: 56px;
}

.tab {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4D1919;
    padding: 22px 34px;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
    margin-top: 40px;
    max-width: 100%;
}

.tab [class^=ico-] {
    font-size: 28px;
}

.tab__item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.tab__item span {
    font-size: 14px;
    font-weight: 600;
}

@media screen and (min-width: 1060px) {
    .tab {
        display: none;
    }
}

.layout--connection {
    column-count: 2;
    column-gap: 1em;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .layout--connection {
        max-width: inherit;
        column-count: inherit;
        max-width: inherit;
        display: flex;
    }
}

.nav__items {
    display: flex;
    align-items: center;
    gap: 48px;
}

.nav__link {
    display: flex;
    gap: 8px;
    align-items: center;
    opacity: 0.4;
    color: #4D1919;
    transition: opacity 0.3s ease-in-out;
    border: none;
    background: none;
    cursor: pointer;
    font-size: inherit;
    font-family: 'Poppins', sans-serif;
}

.nav__link [class^=ico-] {
    font-size: 28px;
    color: #4D1919;
}

.nav__link.is-active {
    opacity: 1;
}

.nav__link:hover {
    text-decoration: none;
    opacity: 1;
}

.options {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.options__item {
    padding: 19px 14px;
}

.options__button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 100%;
    gap: 10px;
    font-size: 16px;
    transition: color 0.3s ease-in-out;
    color: rgba(26, 26, 26, 0.6);
}

.options__button i {
    transition: color 0.3s ease-in-out;
}

.options__button > *:last-child {
    margin-inline: auto 0;
    color: rgba(26, 26, 26, 0.1);
    font-size: 12px;
}

.options__button > *:first-child {
    color: rgba(0, 0, 0, 0.4);
}

.options__button:hover {
    text-decoration: none;
    color: #3798EE;
}

.options__button:hover i {
    color: #3798EE;
}

.card {
    display: flex;
    flex-direction: column;
    background-color: #FEFEFE;
    border-radius: 20px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.05);
    max-width: 332px;
    transition: box-shadow 0.3s ease-out, transform 0.3s ease-out, opacity 0.2s ease-out;
    transition-delay: 0.1s;
    word-wrap: break-word;
}

.card.benefit-card.card-destacada {
    width: 280px;
}

.page--benefits .benefits {
    justify-content: flex-start;
}

.page--benefits .benefits .card {
    max-width: 326px;
    width: 326px;
    min-width: 326px;
}

.card.benefit-card .title {
    overflow: hidden;
    height: 24px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.card__content {
    border-radius: 20px;
    display: flex;
    gap: 1rem;
    padding: 0.5em;
    background-color: white;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.05);
}

.card__content__destacada {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, .05);
    display: flex;
    gap: 1rem;
}

.card__thumb {
    flex-basis: 104px;
    width: 104px;
    height: 104px;
    border-radius: 12px;
    background-color: #E9EEF0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 64px;
}

.card__thumb__destacada {
    height: 190px;
    position: relative !important;
}

.card__thumb__img {
    background-color: rgba(255, 255, 255, 0.14);
    justify-content: center;
    align-items: center;
    font-size: 64px;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    width: initial;
    height: initial;
    opacity: 1;
    border: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 20px 20px 0 0;
}

.card__thumb [class^=ico-], .card__thumb [class*=" ico-"],
.card__thumb__img [class^=ico-], .card__thumb__img [class*=" ico-"] {
    font-size: 32px;
}

.card__thumb img {
    object-fit: cover;
    pointer-events: none;
    user-select: none;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    border-radius: 12px;
    object-position: center;
}

.card__thumb__img img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
}

.card__info {
    flex-basis: 62%;
    flex-grow: 999;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.card__info__destacada {
    flex-basis: 62%;
    flex-grow: 999;
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    gap: 8px;
}

.card__info__destacada img {
    height: auto;
    width: auto;
    object-fit: contain;
}

.card__info img {
    height: 18px;
}

@media screen and (min-width: 768px) {
    .card.benefit-card .card__content {
        flex-direction: row !important;
    }

    .card__status.color-benefits.card__destacados {
        display: block !important;
    }
}

.card__info a {
    text-decoration: none;
}

.card__status {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 12px;
    font-weight: 500;
    height: 100%;
}

.card__status:hover {
    text-decoration: none;
    color: inherit;
}

.card__status.color-benefits:hover {
    color: #0BD592;
}

.card--accent {
    background-color: #2968B0;
    color: white;
}

.card--accent .card__status {
    justify-content: flex-start;
    padding: 0.8rem;
}

.card--accent .card__status a {
    color: white;
}

.card--accent .card__status a:hover {
    text-decoration: none;
}

.card--accent .card__info {
    min-height: 100px;
    display: flex;
    justify-content: center;
}

.card--accent .card__info .title {
    font-size: 20px;
    line-height: 24px;
}

.card--accent .card__content {
    background-color: #3798EE;
}

.card--company {
    padding: 16px 24px;
    display: inline-flex;
    place-content: center;
    height: auto;
    width: 100%;
    margin-bottom: 1rem;
    min-height: 80px;
    align-items: center;
}

@media screen and (min-width: 1060px) {
    .card--company {
        max-width: 160px;
    }
}

.card--company img {
    width: 100%;
    height: auto;
}

@media screen and (min-width: 1060px) {
    .card:hover {
        text-decoration: none;
        box-shadow: rgba(45, 45, 45, 0.05) 0 2px 2px,
        rgba(49, 49, 49, 0.05) 0 4px 4px,
        rgba(42, 42, 42, 0.05) 0 8px 8px,
        rgba(32, 32, 32, 0.05) 0 16px 16px,
        rgba(49, 49, 49, 0.05) 0 32px 32px;
        transform: translate(0, -4px);
        z-index: 999;
    }
}

.badge {
    border-radius: 1.5rem;
    background-color: white;
    box-shadow: 0 0.75rem 1.25rem rgba(0, 0, 0, 0.05);
    padding: 0.625rem 0.875rem;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 100%;
    cursor: pointer;
}

.badge i {
    font-size: 0.75rem;
    opacity: 0.3;
}

.badge b {
    font-weight: 600;
    font-size: 1rem;
}

.badge--likes.is-active {
    background-color: #EE3743;
    color: white;
}

@media screen and (min-width: 1060px) {
    .badge--likes:hover {
        background-color: #EE3743;
        color: white;
    }
}

.badge--circle {
    padding: 0.875rem;
    line-height: 100%;
    color: #4D1919;
}

.badge--circle i {
    opacity: 1;
}

.badge--circle:hover {
    color: white;
    background-color: #4D1919;
}

.badges {
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.block {
    background-color: #F1F6F9;
    padding: 1rem;
    position: relative;
}

.block--round {
    border-radius: 1.25rem 1.25rem 0 0;
}

.block .subhead {
    margin-bottom: 1.25rem;
}

.letterhead + .block {
    transform: translateY(-1rem);
}

.block__foot {
    padding-block: 2rem;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block__foot.is-sticky {
    position: sticky;
    bottom: -1rem;
    background: #f1f6f9;
    background: linear-gradient(180deg, rgba(241, 246, 249, 0) 0%, #f1f6f9 100%);
}

.block__foot.is-sticky .btn {
    box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.05), 0 0.75rem 1.25rem 0 rgba(0, 0, 0, 0.1);
}

.block__foot .btn {
    padding-inline: 1rem;
}

.notifications {
    padding-inline: 24px;
}

.notifications .label {
    font-size: 14px;
    line-height: 20px;
    color: #1A1A1A;
    padding-bottom: 8px;
    border-bottom: solid 1px rgba(26, 26, 26, 0.1);
    display: block;
    margin-bottom: 1rem;
}

.notify {
    display: flex;
    gap: 12px;
    align-items: center;
    padding-block: 16px;
    border-bottom: solid 1px rgba(26, 26, 26, 0.1);
    justify-content: space-between;
    margin-bottom: 16px;
}

.notify__info {
    gap: 12px;
    display: flex;
    align-items: center;
}

.notify__profile {
    min-width: 40px;
    height: 40px;
    border-radius: 40px;
    border: solid 2px white;
    background-color: white;
    overflow: hidden;
}

.notify__text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #1A1A1A;
}

.notify__avatar {
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
}

.notify__date {
    font-size: 12px;
    color: rgba(26, 26, 26, 0.6);
    margin-left: 0.25rem;
}

.notify__calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    background-color: rgba(26, 26, 26, 0.2);
    border-radius: 4px;
    min-width: 40px;
    font-size: 14px;
    color: #fff;
    line-height: 100%;
}

.notify__calendar small {
    font-size: 8px;
}

.modal {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 1;
}

.modal__top {
    padding-inline: 24px;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal__letterhead {
    padding-inline: 24px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 11px;
}

.modal__content {
    padding-inline: 24px;
}

.modal__footer {
    padding: 1rem;
    border-radius: 20px 20px 0 0;
    background-color: white;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.modal__footer .btn {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .modal__footer .btn {
        width: inherit;
    }
}

.letterhead {
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    gap: 24px;
    background-color: #EBF1F4;
}

.letterhead__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    position: relative;
}

.letterhead__cover {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
}

.letterhead .store {
    width: 120px;
    flex-basis: 120px;
    height: 120px;
    font-size: 48px;
}

@media screen and (min-width: 1060px) {
    .letterhead {
        background-color: transparent;
        margin-block: 50px;
        align-items: center;
        justify-content: space-between;
        text-align: center;
    }
}

.letterhead--bgnone {
    background-color: transparent;
}

.letterhead--column {
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 2.5rem;
    gap: 0;
}

.letterhead--default {
    background-color: #4D1919;
    color: white;
}

.letterhead--default a, .letterhead--default button {
    color: white;
}

.letterhead--likes {
    background-color: #EE3743;
    color: white;
}

.letterhead--likes a {
    color: white;
}

.letterhead--back::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background: #ff5500;
    background: linear-gradient(180deg, #ff5500 0%, rgba(38, 89, 115, 0) 100%);
}

.letterhead--back .launch {
    z-index: 2;
    position: relative;
}

.filters {
    margin-block: 1rem;
}

.filters .swiper-slide {
    width: auto;
}

.tag {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E6EDF2;
    font-size: 14px;
    line-height: 1rem;
    color: #4D1919;
    text-align: center;
    padding: 6px 12px;
    border-radius: 28rem;
    font-weight: 500;
    gap: 0.5rem;
    border: none;
    transition: unset;
}

.tag:hover, .tag.is-active {
    background-color: #4D1919;
    color: white;
}

.store {
    background-color: white;
    border: solid 4px #fff;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
    width: 92px;
    flex-basis: 92px;
    height: 92px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.store img {
    object-fit: cover;
    width: 100%;
    pointer-events: none;
    user-select: none;
}

.reaction {
    min-width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #4D1919;
}

.reaction--like {
    background-color: #3798EE;
}

.reaction--dislike {
    background-color: #AB37EE;
}

.reaction--upholder {
    background-color: #EE7437;
}

.benefit {
    width: 100%;
    height: 6.5rem;
    position: relative;
    border-radius: 1.25rem;
    overflow: hidden;
    display: flex;
    box-shadow: 0 0.75rem 1.25rem 0 rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease-out, transform 0.3s ease-out, opacity 0.2s ease-out;
    transition-delay: 0.1s;
}

.benefit__picture {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
    z-index: 0;
}

.benefit__letterhead {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    background: #ff5500;
    background: linear-gradient(90deg, #ff5500 0%, rgba(38, 89, 115, 0) 50%);
    color: white;
}

.benefit__letterhead i {
    font-size: 2.5rem;
}

@media screen and (min-width: 1060px) {
    .benefit__letterhead {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .benefit__letterhead strong {
        font-size: 1.25rem;
    }

    .benefit__letterhead i {
        font-size: 2.375rem;
    }
}

.benefit__letterhead.is-benefit {
    background-color: #0BD592;
}

.benefit--basic {
    background-color: #4D1919;
}

.benefit--basic .benefit__letterhead {
    background: none;
}

.benefit--green {
    background-color: #0BD592;
}

.benefit--green .benefit__letterhead {
    background: none;
}

.benefit .notify {
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid white;
    border-radius: 1.25rem;
    background-color: #EE3743;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 100%;
    padding-block: inherit;
}

@media screen and (min-width: 1060px) {
    .benefit {
        height: 120px;
        max-width: 327px;
    }

    .benefit:hover {
        text-decoration: none;
        box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px, rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px, rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px, rgba(35, 35, 35, 0.05) 0px 64px 64px;
        transform: translate(0, -4px);
        z-index: 999;
    }
}

.header {
    display: none;
    padding: 18px 32px;
    position: sticky;
    top: 0;
    background-color: #F1F6F9;
    z-index: 1000;
}

.header .center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .brand {
    display: block;
}

.header .brand img {
    width: auto;
    height: 2.25rem;
}

@media screen and (min-width: 1060px) {
    .header {
        display: block;
    }
}

.page {
    position: relative;
    width: 100%;
}

.page--profile {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    min-height: 100vh;
}

@media screen and (min-width: 1060px) {
    .page--profile {
        min-height: auto;
    }
}

.page--commerce {
    min-width: 100%;
}

.page--commerce .stores {
    padding-inline: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
}

.page--commerce .stores .store {
    background-color: #fff;
    border: solid 4px #fff;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
    /* max-width: 164px; */
    flex-basis: 160px;
    min-height: 160px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.page--benefits {
    min-width: 100%;
}

.page--benefits .benefits {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-inline: 0;
    justify-content: center;
}

@media screen and (min-width: 1060px) {
    .page--benefits .benefits {
        justify-content: flex-start;
    }

    .page--benefits .benefits .card {
        max-width: 326px;
    }
}

@media screen and (min-width: 1060px) {
    .page {
        width: 1061px;
        min-width: inherit;
    }

    .page--full {
        width: 100%;
    }
}

.home {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*height: 100vh;*/
}

.home__header {
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    flex-direction: row-reverse;
}

@media screen and (min-width: 1060px) {
    .home__header {
        margin-block: 50px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.home .search {
    padding-inline: 24px;
    padding-bottom: 24px;
}

@media screen and (min-width: 1060px) {
    .home .search {
        display: none;
    }
}

.home .subhead {
    margin-bottom: 20px;
}

@media screen and (min-width: 1060px) {
    .home {
        width: 1061px;
        height: auto;
    }
}

.home .swiper {
    padding-bottom: 45px;
}

.setting {
    border: none;
    background-color: transparent;
    padding: 0;
    color: #4D1919;
}

.setting .ico-setting {
    font-size: 24px;
    opacity: 0.4;
}

@media screen and (min-width: 1060px) {
    .setting {
        display: none;
    }
}

.featured {
    padding-inline: 24px;
    width: 100%;
    overflow: hidden;
}

@media screen and (min-width: 1060px) {
    .featured {
        display: none;
    }
}

.stores {
    padding-inline: 24px;
    width: 100%;
}

@media screen and (min-width: 1060px) {
    .stores {
        order: 2;
        max-width: inherit;
        margin-bottom: 50px;
    }
}

.benefits {
    padding-inline: 24px;
}

.benefits__corporatives {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.benefits__corporatives .card {
    display: none;
}

@media screen and (min-width: 1060px) {
    .benefits__corporatives {
        margin-bottom: 24px;
    }

    .benefits__corporatives .card {
        display: flex;
        max-width: 327px;
    }
}

.benefits__categories {
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.benefits__categories:last-child {
    margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
    .benefits__categories {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.benefits__grid {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    width: 100%;
}

@media screen and (min-width: 1060px) {
    .benefits__grid {
        max-width: 327px;
    }
}

@media screen and (min-width: 1060px) {
    .benefits__grid .benefit .benefit__letterhead {
        flex-direction: column;
        justify-content: space-between;
    }

    .benefits__grid .benefit .benefit__letterhead .title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 120%;
    }
}

.benefits__grid .benefit:last-child {
    display: none;
}

@media screen and (min-width: 1060px) {
    .benefits__grid .benefit:last-child {
        display: flex;
    }
}

@media screen and (min-width: 1060px) {
    .benefits__grid .benefit {
        max-width: 164px;
        width: 50%;
        height: 100%;
    }
}

@media screen and (min-width: 1060px) {
    .benefits {
        order: 1;
        margin-bottom: 34px;
    }
}

.splash {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.splash__items {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    top: 0;
}

.splash__item {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 1s;
}

.splash__picture {
    background-color: #e7edf2;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    border-radius: 0 0 50% 50%;
    flex-grow: 0;
}

.splash__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 24px;
    flex-basis: 100%;
    flex-shrink: 1;
    flex-grow: 0;
    justify-content: center;
    gap: 8px;
}

.splash__content .subtitle, .splash__content .profile__name {
    margin-bottom: 0;
}

.splash__content .dots {
    margin-block: 24px;
}

@media screen and (min-width: 768px) {
    .splash {
        height: inherit;
        max-width: 648px;
        max-height: 530px;
        margin-inline: auto;
        border-radius: 20px;
        box-shadow: 0 4px 10px 0 rgba(32, 88, 115, 0.05);
        overflow: hidden;
        justify-content: space-between;
    }
}

.login {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    justify-content: space-between;
    gap: 32px;
}

@media screen and (min-width: 768px) {
    .login {
        max-width: 340px;
        margin-inline: auto;
    }
}

.login .form {
    min-width: 295px;
}

.login .form .form__field .btn {
    width: 100%;
}

.auth-services {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.dialog {
    max-width: 648px;
    width: 100%;
    position: fixed;
    z-index: 999;
    height: auto;
    max-height: 95vh;
    bottom: 0;
    left: 0;
    right: 0;
    margin-inline: auto;
    background-color: #FCFCEE;
    box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.05);
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    overflow-y: auto;
}

.dialog__headlong {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding-inline: 24px;
}

.dialog__head {
    position: relative;
    border-radius: 20px 20px 0 0;
    min-height: 248px;
    overflow: hidden;
    z-index: 1;
}

.dialog__head::before {
    content: "";
    background: #ff9933;
    background: linear-gradient(180deg, #ff9933 0%, #ff2a0000 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 1;
}

.dialog__title {
    font-size: 18px;
    color: white;
    line-height: 24px;
    font-weight: 700;
}

.dialog__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    z-index: 2;
    position: relative;
}

.dialog__actions .action {
    color: white;
    opacity: 0.7;
}

.dialog__actions .action.align-end {
    margin-left: auto;
    margin-right: 0;
}

.dialog__cover {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
}

.dialog__status {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.75rem;
    margin-bottom: 1rem;
}

.dialog__status [class^=ico-], .dialog__status [class*=" ico-"] {
    font-size: 20px;
}

.dialog__legal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1.5rem 1rem;
    max-width: 480px;
    margin-inline: auto;
    text-align: center;
}

.dialog__legal .caption, .dialog__legal .card__corporation {
    margin-bottom: 0 !important;
}

.dialog__legal p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0 !important;
}

.dialog__indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
}

.dialog__indicator i {
    font-size: 0.75rem;
    color: rgba(32, 88, 115, 0.3);
}

@media screen and (min-width: 1060px) {
    .dialog__indicator {
        display: none;
    }
}

.dialog__contet {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px 20px 0 0;
    position: relative;
    z-index: 2;
    transform: translateY(-48px);
    background-color: #F9FCFE;
    margin-bottom: -48px;
}

.dialog__contet .store {
    width: 160px;
    flex-basis: 160px;
    height: 160px;
    margin-top: -80px;
    margin-bottom: 20px;
    background-color: #E9EEF0;
    font-size: 64px;
}

.dialog__contet .store [class^=ico-], .dialog__contet .store [class*=" ico-"] {
    font-size: 48px;
    color: #4D1919;
}

.dialog__contet .caption, .dialog__contet .card__corporation,
.dialog__contet .lead,
.dialog__contet .input--search {
    margin-bottom: 0.5rem;
}

.dialog__contet .subtitle, .dialog__contet .profile__name {
    margin-bottom: 1rem;
}

.dialog__contet .sponsor {
    margin-bottom: 24px;
}

.dialog__contet .card__info {
    text-align: left;
}

.dialog__contet .form {
    width: 100%;
    max-width: 327px;
}

.dialog__info {
    padding-inline: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dialog__relations {
    background-color: #F9FCFE;
    box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding-inline: 1rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    padding-bottom: 48px;
}

@media screen and (min-width: 1060px) {
    .dialog__relations {
        display: none;
    }
}

.dialog__foot {
    display: flex;
    justify-content: center;
    padding: 40px;
}

.dialog--clear .dialog__title {
    color: #4D1919;
}

.dialog--clear .dialog__head {
    min-height: inherit;
    margin-bottom: 24px;
}

.dialog--clear .dialog__head::before {
    display: none;
}

.dialog--clear .dialog__contet {
    transform: none;
    margin-bottom: 0;
}

.dialog--clear .dialog__cover {
    display: none;
}

.dialog--clear .dialog__actions .action {
    color: rgba(32, 88, 115, 0.3);
}

.dialog--clear .dialog__actions .action:hover {
    color: rgba(32, 88, 115, 0.7);
}

@media screen and (min-width: 1060px) {
    .dialog {
        bottom: inherit;
        border-radius: 20px;
        top: 50%;
        transform: translateY(-50%);
    }

    .dialog--small {
        max-width: 374px;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #FCFCEE;
}

.backdrop-whited {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #F1F6F9;
}


.toggle--options {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.toggle__option {
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 500;
    line-height: 100%;
    padding: 1rem 1.25rem;
    border-radius: 20px;
    width: 50%;
    min-width: 150px;
    text-align: center;
}

.toggle__option.is-active {
    background-color: #4D1919;
    color: white;
}

.toggle__option:hover {
    cursor: pointer;
}

.calendar {
    width: 100%;
    max-width: 320px;
    margin: 1rem auto;
    font-family: "Poppins", sans-serif;
}

.calendar .datepicker-picker {
    background-color: transparent;
    width: 100%;
}

.calendar .datepicker-picker .datepicker-header .datepicker-controls {
    gap: 2px;
}

.calendar .datepicker-picker .datepicker-header .datepicker-controls .button {
    border-radius: 1rem;
    box-shadow: 0 0.75rem 1.25rem 0 rgba(0, 0, 0, 0.05);
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: normal;
    color: #4D1919;
    min-height: 2.75rem;
    min-width: 2.75rem;
}

.calendar .datepicker-picker .datepicker-main .datepicker-view {
    width: 100%;
}

.calendar .datepicker-picker .datepicker-main .datepicker-view .days {
    width: 100%;
    margin-top: 10px;
}

.calendar .datepicker-picker .datepicker-main .datepicker-view .days .datepicker-grid {
    width: 100%;
    gap: 2px;
}

.calendar .datepicker-picker .datepicker-main .datepicker-view .days .datepicker-grid .datepicker-cell {
    height: 43px;
    max-width: 43px;
    line-height: 43px;
    font-size: 14px;
    font-weight: 400;
    color: #4D1919;
    background-color: #fff;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
}

.calendar .datepicker-picker .datepicker-main .datepicker-view .days .datepicker-grid .datepicker-cell.disabled {
    color: #778699;
    box-shadow: none;
    background-color: rgba(26, 26, 26, 0.05);
}

.calendar .datepicker-picker .datepicker-main .datepicker-view .days .datepicker-grid .datepicker-cell.selected, .calendar .datepicker-picker .datepicker-main .datepicker-view .days .datepicker-grid .datepicker-cell.focused {
    background-color: #4D1919 !important;
    color: white;
}

.calendar .datepicker-picker .datepicker-main .datepicker-view .days .datepicker-grid .datepicker-cell.focused:not(.selected) {
    color: white;
    background-color: #4D1919 !important;
}

.calendar .datepicker-picker .datepicker-main .datepicker-view .days .days-of-week .dow {
    color: #778699;
    font-size: 14px;
    font-weight: 400;
}

.calendar .datepicker-picker .datepicker-main .datepicker-view.months .datepicker-cell.disabled {
    color: #778699;
    box-shadow: none;
    background-color: rgba(26, 26, 26, 0.05);
}

.calendar .datepicker-picker .datepicker-main .datepicker-view.months .datepicker-cell.selected, .calendar .datepicker-picker .datepicker-main .datepicker-view.months .datepicker-cell.focused {
    background-color: #4D1919 !important;
    color: white;
}

.calendar .datepicker-picker .datepicker-main .datepicker-view.months .datepicker-cell.focused:not(.selected) {
    color: white;
    background-color: #4D1919 !important;
}

.checks__item input {
    visibility: hidden;
    position: absolute;
}

.checks__item label {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
}

.checks__item label::before {
    content: "";
    font-family: "icopeoplefirst";
    width: 28px;
    height: 28px;
    line-height: 28px;
    border: solid 2px #4D1919;
    display: block;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
    line-height: 24px;
    text-align: center;
}

.checks__item input:checked ~ label::before {
    content: "\e92a";
    background-color: #4D1919;
    color: white;
    font-size: 0.75rem;
}

.radios {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 24px;
}

.radios__item input {
    visibility: hidden;
    position: absolute;
}

.radios__item label {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
}

.radios__item label::before {
    content: "";
    font-family: "icopeoplefirst";
    width: 28px;
    height: 28px;
    line-height: 28px;
    border: solid 2px #4D1919;
    display: block;
    margin-right: 0.5rem;
    border-radius: 28px;
    line-height: 24px;
    text-align: center;
}

.radios__item input:checked + label::before {
    content: "\e92a";
    background-color: #4D1919;
    color: white;
    font-size: 0.75rem;
}

.radios--options {
    width: 100%;
    max-width: 327px;
}

.radios--options label {
    justify-content: space-between;
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.05);
    line-height: 100%;
}

.radios--options label::before {
    order: 2;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    border: none;
    background-color: rgba(26, 26, 26, 0.05);
}

.radios--options input:checked ~ label {
    background-color: #4D1919;
    color: white;
}

.radios--options input:checked + label::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.6);
}

.search-top {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: inherit;
    background-color: white;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 5px 20px 0 rgba(10, 8, 8, 0.05);
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.search-top .form {
    width: 100%;
}

.search-top .input--search {
    text-align: left;
    max-width: inherit;
    font-weight: 600;
    background-image: url("../images/ico-search-bg.svg");
    background-repeat: no-repeat;
    background-position: 1rem center;
    padding-left: 2.5rem;
}

.search-top button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 32px;
    line-height: 100%;
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;
    color: #4D1919;
}

.search-top button:hover {
    cursor: pointer;
}

.results {
    margin-top: 80px;
    position: relative;
    z-index: 999;
    padding: 1rem;
}

.list-style-closed {
    list-style: disclosure-closed;
}

.hidden {
    display: none !important;
}

.modal__container {
    height: 100%;
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: #F1F6F9;
    display: flex;
    justify-content: center;
    overflow: auto;
}

.Toastify {
    position: absolute;
    z-index: 100000;
}

input {
    color: #205772;
}

.d-flex {
    display: flex;
}

.pointer {
    cursor: pointer;
}

.sin-imagen {
    background: #ff9933;
}

.swiper-slide-auto {
    width: auto !important;
}

.home__header button {
    align-self: center;
}

.categories {
    padding-inline: 24px;
    margin-bottom: 200px;
}

.flex-grow-1 {
    flex-grow: 1;
}

@media screen and (min-width: 1060px) {
    .categories {
        flex-grow: 0;
        margin-bottom: 30px;
        order: 1;
    }
}

a.no-decoration {
    text-decoration: none;
}

.vh100 {
    height: 100vh;
}

.w100 {
    width: 100%;
}

@media screen and (min-width: 1060px) {
    .search-container {
        width: 1061px;
        min-width: inherit;
    }
}

.justify-contenet-center {
    justify-content: center;
}

.tag.disabled {
    cursor: default;
}

.tag.disabled:hover {
    background-color: #E6EDF2;
    color: #4D1919;
}

.direction-column {
    flex-direction: column;
}

.justify-content-space-bet {
    justify-content: space-between;
}


.search-result {
    position: relative;
    z-index: 999;
}

.page.page--benefits.search-result {
    height: 100%;
    min-height: 100%;
    flex: 1;
}

.infinite-scroll-component__outerdiv {
    display: flex;
    width: 100%;
}

.font-64 {
    font-size: 64px;
}

.font-16 {
    font-size: 16px;
}

.card--company.is-active {
    background-color: #0bd592;
    color: white;
}

.ai-fs {
    align-items: flex-start;
}

.max-30 {
    max-height: 30px;
}

.card.card--company {
    height: 128px;
    width: 128px;
}

.dialog__info .caption, .dialog__legal .caption {
    white-space: pre-wrap;
}

.launch img {
    max-width: 128px;
}

.sponsor img {
    height: 20px;
}

.m-auto {
    margin: auto;
}

.bold {
    font-weight: bold;
}

.flex-1 {
    flex: 1;
}

.direction-row {
    flex-direction: row;
}

.justify-content-space-around {
    justify-content: space-around;
}

.flex-wrap {
    flex-wrap: wrap;
}

.title-h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
}

.card.benefit-card.card-destacada .card__content__destacada {
    flex-direction: column;
}

.card__status.color-benefits.card__destacados {
    display: none;
}

.home__header .brand img {
    height: 60px;
}

@media screen and (min-width: 1060px) {
    .home__header {
        display: none;
    }
}

#tabBottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

.card__back__commerce {
    width: 50px;
    height: 50px;
    display: flex;
    position: absolute;
    bottom: 0;
    z-index: 1;
}

.card__back__commerce > img {
    height: auto;
}

.card__commerce {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
}

.card__commerce img {
    width: 100%;
    object-fit: contain;
    position: absolute;
    left: 21px;
    top: 14px;
}

.text-commerce {
    font-size: 14px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 18px;
}

.img-commerce-footer {
    max-height: fit-content;
}

span.card__footer_item {
    flex: 1 1 0;
    width: 0;
    height: 100%;
}

p.card__footer_item {
    width: 100%;
    height: 0;
    flex: 0 1 0px;
}

.card__corporation p.card__footer_item:last-child {
    flex-grow: 1;
}

.card__footer_item > img {
    object-fit: contain;
    width: 100%;
}

.card__foooter__separator {
    width: 100%;
    color: #F93;
    border: solid 1px #F93;
}

.hide-me {
    visibility: hidden;
    margin-bottom: 40px;
}